<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-data-table
      :headers="headers"
      :items="displayedItems"
      :items-per-page="-1"
    >
      <template v-slot:item.status="{ item }">
        <v-chip
          :color="getColor(item.status)"
          dark
        >
          {{ item.status || "unknown" }}
        </v-chip>
      </template>
      <template v-slot:item.invoice="{ item }">
        <v-btn
          v-if="$admin.can('order-view')"
          fab
          x-small
          color="blue-grey"
          :loading="is_loading_invoice && index == item.id"
          :disabled="is_loading_invoice && index == item.id"
          @click="printInvoice(item)"
        >
          <v-icon> mdi-download </v-icon>
        </v-btn>
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ formatDateTime(item.created_at) }}
      </template>

      <template v-slot:no-data>
        {{ $t("no_data_available") }}
      </template>
      <template v-slot:top>
        <div
          style="gap: 10px"
          class="d-flex mx-2 mt-2"
        >
          <v-text-field
            outlined
            dense
            v-model="options.search"
            :label="$t('search')"
            clearable
            style="flex: 1 1 0"
          ></v-text-field>
          <date-time-picker-input
            :autoClose="false"
            :label="$t('start_date')"
            v-model="options.start_at"
            style="flex: 1 1 0"
          ></date-time-picker-input>
          <date-time-picker-input
            :label="$t('end_date')"
            v-model="options.end_at"
            style="flex: 1 1 0"
          ></date-time-picker-input>
        </div>
      </template>
    </v-data-table>
    <invoice-a7
      ref="invoiceA7"
      :order="posOrder"
    ></invoice-a7>
  </v-container>
</template>

<script>
// import _ from 'lodash'
import moment from "moment";
import debounce from "lodash/debounce";
import Localbase from "localbase";
import { network } from "@/mixins/network";

let db = new Localbase("db");
db.config.debug = false;
export default {
  mixins: [network],

  components: {
    DateTimePickerInput: () => import("./modals/DateTimePickerInput.vue"),
    invoiceA7: () => import("../../pdf/pos/invoiceA7New.vue"),
  },

  mounted() {
    this.loadLocalOrders();
  },

  computed: {},

  watch: {
    options: {
      handler: debounce(async function (options) {
        this.displayedItems = this.listLocalOrders;
        this.displayedItems = this.listLocalOrders?.filter(function (item) {
          var result = true;
          if (options.search) {
            result = item.tracking_number_ext
              ?.toLowerCase()
              ?.includes(options.search.toLowerCase());
          }
          if (options.start_at) {
            result = new Date(item.created_at) > new Date(options.start_at);
          }
          if (options.end_at) {
            result = new Date(item.created_at) < new Date(options.end_at);
          }
          return result;
        });
      }, 1000),
      deep: true,
    },
  },

  data() {
    return {
      options: {},
      index: 0,
      is_loading_invoice: false,
      isSyncOrders: false,
      listLocalOrders: [],
      displayedItems: [],
      posOrder: {},
      headers: [
        {
          text: "ID",
          align: "start",
          value: "tracking_number_ext",
        },
        {
          text: this.$t("status"),
          align: "start",
          value: "status",
        },
        {
          text: this.$t("ttc"),
          align: "center",
          value: "ttc",
          sortable: false,
          width: "15%",
        },
        {
          text: this.$t("reduction"),
          align: "center",
          value: "reduction",
          sortable: false,
        },
        {
          text: this.$t("stamp_amount"),
          align: "center",
          value: "stamp_amount",
          sortable: false,
        },

        {
          text: this.$t("total"),
          align: "center",
          value: "total",
          sortable: false,
        },

        {
          text: this.$t("invoice"),
          value: "invoice",
        },
        {
          text: this.$t("created_at"),
          value: "created_at",
        },
      ],
    };
  },

  methods: {
    loadLocalOrders() {
      db.collection("orders_history")
        .get()
        .then((orders) => {
          this.listLocalOrders = orders ?? [];
          this.displayedItems = this.listLocalOrders;
        });
    },

    async printInvoice(item) {
      this.posOrder = item;
      setTimeout(() => {
        this.$refs.invoiceA7.generateReport();
      }, 500);
    },

    formatDateTime(dateTime) {
      return moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
    },
    getColor(status) {
      if (status === "Synced") return "green";
      else return "red";
    },
  },
};
</script>
